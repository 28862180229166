@charset "UTF-8";

@import "foundation/_mixin";
@import "foundation/_reset";
@import "foundation/_base";
@import "component/_component";

@import "page/_index";
@import "page/_outpatient";
@import "page/_about";
@import "page/_staff";
@import "page/_visitingcare";
@import "page/_recruit";
@import "page/_contact";


.dummy {
  color: red!important;
}
