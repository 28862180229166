@include sp-screen {}

.page-outpatient {
  .kv {
    padding-top: 0;
    // background: url(../img/outpatient/key_visual.png) no-repeat center bottom;
    // background-size: cover;
    // @include sp-screen {
    //   background-position: center top;
    // }
  }


  .main-kv {
    position: relative;
    &__text {
      position: absolute;
      top: 150px;
      left: 0;
      width: 70%;
      height: 240px;
      background: rgba(0,172,189,0.7);
      @include sp-screen {
        width: 80%;
        top: 80px;
        height: 320px;
      }
    }
    .inner {
      max-width: 1024px;
      margin: 0 auto;
      height: 0;
    }
  }
  .keyvisual-inner-wrap {
    padding-top: 0;
    position: relative;
  }
  .keyvisual-slider {
    overflow: hidden;
    height: 70vh;
    max-height: 560px;
    &__main {
      transform-origin: center center;
      .bg-images1 {
        height: 70vh;
        max-height: 560px;
        background: url(../img/top/kv_img_1.jpg) no-repeat center bottom;
        background-size: cover;
        @include sp-screen {
          background: url(../img/top/kv_img_sp_1.jpg) no-repeat center bottom;
        }
      }
      .bg-images2 {
        height: 70vh;
        max-height: 560px;
        background: url(../img/top/kv_img_2.png) no-repeat center bottom;
        background-size: cover;
        @include sp-screen {
          background: url(../img/top/kv_img_sp_2.png) no-repeat center bottom;
          width: auto;
        }
      }
      .bg-images3 {
        height: 70vh;
        max-height: 560px;
        background: url(../img/top/kv_img_3.png) no-repeat center bottom;
        background-size: cover;
        @include sp-screen {
          background: url(../img/top/kv_img_sp_3.png) no-repeat center bottom;
        }
      }
    }
  }

  .title-area-wrap {
    transform: translateY(-380px);
    @include sp-screen {
      transform: translateY(-370px);
    }
    .inner {
      width: 70%;
      margin: 0 auto 0 0;
      @include sp-screen{
        margin: 0 auto 0 5%;
      }
    }
  }
  .title-area {
    color: #fff;
    &__title {
      font-size: 3rem;
      display:flex;
      align-items:center;
      margin-bottom: 20px;
      font-weight: bold;
      @include sp-screen {
        font-size: 2rem;
        align-items:baseline;
        margin-bottom: 15px;
      }
      &::before {
        margin-right:10px;
        border-top:1px solid #fff;
        content:"";
        flex-grow:0.3;
      }
    }
    &__text {
      font-size: 1.6rem;
      margin-bottom: 30px;
      @include sp-screen {
        font-size: 1.4rem;
        margin-bottom: 15px;
      }
    }
    &__tw {
      text-align: right;
      width: 90%;
      font-size: 1.6rem;
      @include sp-screen {
        text-align: left;
        font-size: 1.4rem;
      }
      img{
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
        @include sp-screen {
          width: 17px;
          margin-right: 4px;
        }
      }
      a{
        color: #fff;
      }
    }

  }
  .content {
    counter-reset: number;
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(30);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(20);
      }
    }
  }
  .outpatient {
    @include pc-screen {
      width: 1024px;
      margin: 0 auto 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include sp-screen {
      width: auto;
      margin: 0 5% 30px;
    }
    display: flex;
    justify-content: space-between;
    @include sp-screen {
      flex-direction: column-reverse;
    }
  }
  .subjects {
    width: 495px;
    margin-bottom: 16px;
    @include sp-screen {
      width: 100%;
      margin-bottom: 0;
      margin-top: 20px;
    }
    dt {
      margin-bottom: 10px;
      padding-bottom: 10px;
      @include rem-font-size(20);
      border-bottom: dotted 2px #c5c5c5;
      letter-spacing: 0.12em;
      @include sp-screen {
        @include rem-font-size(18);
      }
      &.icon_course {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_course.png) no-repeat center;
          background-size: contain;
        }
      }
      &.icon_physical-therapy {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_physical-therapy.png) no-repeat center;
          background-size: contain;
        }
      }
      &.icon_closed {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_closed.png) no-repeat center;
          background-size: contain;
        }
      }
    }
    dd {
      @include rem-font-size(16);
      line-height: 1.5;
      letter-spacing: 0.14em;
      @include sp-screen {
        @include rem-font-size(14);
      }
    }
  }
  .schedule {
    width: 498px;
    @include rem-font-size(16);
    border: solid 1px #c5c5c5;
    @include sp-screen {
      width: 100%;
      @include rem-font-size(14);
    }
    .bg-main {
      background: $mainColor;
      color: $white;
      border-right: solid 1px $white;
      border-bottom: none;
      &:last-child {
        border: none;
      }
    }
    th, td {
      padding: 10px;
      text-align: center;
      border-bottom: solid 1px #c5c5c5;
      border-right: solid 1px #c5c5c5;
      &.align-l {
        text-align: left;
      }
      &:last-child {
        border-right: none;
      }
    }
    td {
      @include rem-font-size(18);
      color: $mainColor;
      @include sp-screen {
        @include rem-font-size(16);
      }
    }
  }
  .note {
    margin-top: 8px;
    @include rem-font-size(13);
  }
  .wrap {
    @include pc-screen {
      width: 800px;
      margin: 0 auto;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  .block {
    margin-bottom: 120px;
    @include sp-screen {
      margin-bottom: 60px;
    }
    &__ttl {
      margin-bottom: 30px;
      @include rem-font-size(30);
      letter-spacing: 0.12em;
      text-align: center;
      @include sp-screen {
        margin-bottom: 15px;
        @include rem-font-size(20);
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 300px;
        width: 200px;
        @include sp-screen {
          padding: 0 64px;
          width: 160px;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 300px;
          height: 1px;
          background-color: $mainColor;
          @include sp-screen {
            width: 64px;
          }
        }
        &::before {
          left:0;
        }
        &:after {
          right: 0;
        }
      }
    }
    &__list {
      list-style: none;
      font-size: 1.6rem;
    }
  }
  .news {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include sp-screen {
      align-items: flex-start;
    }
    &__date {
      margin-right: 2em;
      @include rem-font-size(13);
    }
    &__text {
      @include rem-font-size(16);
      @include sp-screen {
        word-break: break-all;
        @include rem-font-size(14);
      }
    }
  }
  .news-more {
    text-align: right;
    @include rem-font-size(14);
    a {
      position: relative;
      display: inline-block;
      padding: 0 12px 0 0;
      vertical-align: middle;
      text-decoration: none;
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 2px;
        margin: auto;
        vertical-align: middle;
        content: "";
        width: 6px;
        height: 6px;
        border-top: 2px solid $mainColor;
        border-right: 2px solid $mainColor;
        transform: rotate(45deg);
      }
    }
  }
  .access {
    .map {
      width: 980px;
      margin: 0 auto 30px;
      @include sp-screen {
        width: 100%;
        iframe {
          width: 100%;
        }
      }
    }
    .address,
    .info {
      @include rem-font-size(16);
      letter-spacing: 0.14em;
      @include sp-screen {
        @include rem-font-size(14);
      }
    }
    .address {
      margin-bottom: calc(1em * 1.5);
    }
    .info {
      &__ttl {
        color: $mainColor;
        margin-bottom: calc(1em * 1.5);
      }
      &__text {
        //display: flex;
        margin-bottom: calc(1em * 1.5);
        a {
          color: $mainColor;
        }
        &::before {
          content: "□";
        }
      }
    }
  }
}
