@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden; }

html, body {
  font-size: 10px; }

body {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.7;
  color: #333;
  min-width: 1024px; }
  @media screen and (max-width: 769px) {
    body {
      min-width: inherit; } }

.pc-on {
  display: block; }

.sp-on {
  display: none; }

a {
  text-decoration: none;
  transition: all 0.5s;
  color: #333; }

img {
  height: auto !important; }

@media screen and (min-width: 770px) {
  a img {
    transition: opacity 0.5s;
    backface-visibility: hidden; }
  a:hover {
    opacity: 0.7;
    text-decoration: none; }
  a[href^="tel:"] {
    pointer-events: none; }
  .post-cont {
    font-size: 16px; } }

@media screen and (max-width: 769px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: block; }
  img {
    width: 100%; }
  input[type="text"],
  input[type="button"],
  input[type="email"],
  input[type="submit"],
  input[type="password"],
  textarea {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    border-radius: 0; } }

.logo {
  line-height: 1; }
  @media screen and (max-width: 769px) {
    .logo {
      margin-bottom: 16px; } }
  .logo__sub {
    display: block;
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 1.2rem;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 769px) {
      .logo__sub {
        margin-bottom: 0.6rem;
        font-size: 12px;
        font-size: 1.2rem; } }
  .logo__name {
    display: block;
    font-size: 44px;
    font-size: 4.4rem;
    font-weight: bold;
    font-family: 'Kosugi Maru', sans-serif;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .logo__name {
        font-size: 22px;
        font-size: 2.2rem; } }
  .logo__img {
    display: block; }
    .logo__img img {
      width: 400px; }
    @media screen and (max-width: 769px) {
      .logo__img {
        font-size: 22px;
        font-size: 2.2rem; }
        .logo__img img {
          width: 100%; } }
    .logo__img.cat01 {
      text-align: center; }
      @media screen and (max-width: 769px) {
        .logo__img.cat01 {
          font-size: 22px;
          font-size: 2.2rem; }
          .logo__img.cat01 img {
            width: 85%; } }

.header {
  position: relative; }
  @media screen and (max-width: 769px) {
    .header .sp-menu-btn {
      position: absolute;
      display: block;
      width: 30px;
      background-color: #00acbd;
      padding: 12px 8px;
      top: 0;
      right: 0;
      line-height: 0; } }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    padding: 16px 0; }
    @media screen and (max-width: 769px) {
      .header__inner {
        flex-direction: column;
        margin: 0 5%;
        padding: 30px 0 16px 0; } }
  @media screen and (min-width: 770px) {
    .header .catch {
      text-align: right;
      font-size: 14px;
      font-size: 1.4rem;
      letter-spacing: 0.16em; } }
  @media screen and (max-width: 769px) {
    .header .catch {
      margin-bottom: 8px;
      text-align: center;
      font-size: 12px;
      font-size: 1.2rem; } }
  .header .address {
    font-size: 0; }
    @media screen and (min-width: 770px) {
      .header .address {
        text-align: right; } }
    @media screen and (max-width: 769px) {
      .header .address {
        text-align: center; } }
    .header .address dt,
    .header .address dd {
      display: inline-block;
      font-size: 14px;
      font-size: 1.4rem;
      letter-spacing: 0.16em; }
      @media screen and (max-width: 769px) {
        .header .address dt,
        .header .address dd {
          font-size: 12px;
          font-size: 1.2rem; } }
    .header .address dt {
      margin-right: .5em; }
  .header .tel {
    position: relative;
    padding-left: 32px;
    display: flex;
    justify-content: flex-end;
    line-height: 1.4; }
    @media screen and (max-width: 769px) {
      .header .tel {
        display: block;
        padding-left: 0;
        text-align: center;
        justify-content: space-between; } }
    @media screen and (min-width: 770px) {
      .header .tel {
        text-align: right; } }
    .header .tel::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 24px;
      height: 26px;
      background: url(../img/common/icon_tel.png) no-repeat;
      background-size: contain; }
      @media screen and (max-width: 769px) {
        .header .tel::before {
          width: 30px;
          height: 30px;
          left: 7vw; } }
      @media screen and (max-width: 374px) {
        .header .tel::before {
          left: 5vw; } }
    .header .tel dl {
      margin-right: 1em; }
      .header .tel dl:last-child {
        margin-right: 0; }
      @media screen and (max-width: 769px) {
        .header .tel dl {
          display: block;
          width: calc(100% - 8vw);
          padding-left: 8vw; } }
    .header .tel dt,
    .header .tel dd {
      display: inline-block;
      font-size: 22px;
      font-size: 2.2rem; }
      @media screen and (max-width: 769px) {
        .header .tel dt,
        .header .tel dd {
          font-size: 20px;
          font-size: 2rem; } }
    @media screen and (max-width: 769px) {
      .header .tel dt {
        width: 5em;
        text-align: left;
        font-size: 18px;
        font-size: 1.8rem; } }
  .header .small {
    text-align: right; }
    @media screen and (max-width: 769px) {
      .header .small {
        padding-right: 4em; } }

.navi-area {
  border-top: solid 1px #c5c5c5; }
  @media screen and (max-width: 769px) {
    .navi-area {
      position: absolute;
      width: 100%;
      top: 114px;
      left: 0;
      background-color: #fff;
      display: none;
      border-bottom: solid 1px #c5c5c5; } }

.navi {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4;
  letter-spacing: 0.08em; }
  @media screen and (max-width: 769px) {
    .navi {
      flex-wrap: wrap;
      font-size: 13px;
      font-size: 1.3rem; } }
  .navi li {
    width: 20%;
    height: 80px;
    border-left: solid 1px #c5c5c5; }
    .navi li.current {
      background: #00acbd; }
      .navi li.current a {
        color: #fff; }
    @media screen and (max-width: 769px) {
      .navi li {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        border-bottom: solid 1px #c5c5c5; }
        .navi li:nth-child(odd) {
          border-left: none; } }
    .navi li:last-child {
      border-right: solid 1px #c5c5c5; }
      @media screen and (max-width: 769px) {
        .navi li:last-child {
          width: 100%;
          border: none; } }
  .navi a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    height: 80px; }
    @media screen and (max-width: 769px) {
      .navi a {
        height: 40px; } }
    @media screen and (min-width: 770px) {
      .navi a:hover {
        background: #00acbd;
        color: #fff; } }

.kv {
  padding-top: 27%; }

.bottom-link {
  margin-bottom: 50px;
  padding: 30px 0;
  background: rgba(0, 172, 189, 0.1); }
  @media screen and (max-width: 769px) {
    .bottom-link {
      margin-bottom: 25px; } }
  .bottom-link__inner {
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 769px) {
      .bottom-link__inner {
        margin: 0 5%;
        justify-content: space-between; } }
  @media screen and (max-width: 769px) {
    .bottom-link li {
      width: 32%; } }
  .bottom-link__item {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em; }
    @media screen and (min-width: 770px) {
      .bottom-link__item {
        width: 250px;
        height: 230px;
        font-size: 22px;
        font-size: 2.2rem; } }
    @media screen and (max-width: 769px) {
      .bottom-link__item {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 12px;
        font-size: 1.2rem; } }
    .bottom-link__item img {
      width: 140px;
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .bottom-link__item img {
          width: 80px;
          margin-bottom: 15px; } }

.page-top {
  position: relative;
  display: block;
  padding: 40px 0 20px;
  width: 100%;
  background: #f0f0f0;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.08em; }
  @media screen and (max-width: 769px) {
    .page-top {
      padding: 30px 0 5px;
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-top::before {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    content: "";
    display: block;
    width: 28px;
    height: 16px;
    background: url(../img/common/icon_up.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-top::before {
        top: 10px; } }

.footer__inner {
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0 50px; }
  @media screen and (max-width: 769px) {
    .footer__inner {
      margin: 0 5%; } }

.footer__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #c3c3c3;
  padding-bottom: 30px;
  margin-bottom: 20px; }
  @media screen and (max-width: 769px) {
    .footer__card {
      padding-bottom: 15px;
      margin-bottom: 15px;
      flex-direction: column; } }
  .footer__card .logo img {
    width: 400px; }
    @media screen and (max-width: 769px) {
      .footer__card .logo img {
        width: 100%; } }

.footer .address {
  text-align: right;
  font-size: 0; }
  @media screen and (max-width: 769px) {
    .footer .address {
      text-align: left; } }
  .footer .address dt,
  .footer .address dd {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    letter-spacing: 0.16em; }
    @media screen and (max-width: 769px) {
      .footer .address dt,
      .footer .address dd {
        font-size: 12px;
        font-size: 1.2rem; } }
  .footer .address dt {
    margin-right: .5em; }

.footer .tel {
  position: relative;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 1.4; }
  @media screen and (max-width: 769px) {
    .footer .tel {
      text-align: left;
      justify-content: flex-start; } }
  .footer .tel dl {
    margin-right: 1em;
    letter-spacing: 0.16em; }
    .footer .tel dl:last-child {
      margin-right: 0; }
  .footer .tel dt {
    margin-right: 0.5em; }
  .footer .tel dt,
  .footer .tel dd {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem; }
    @media screen and (max-width: 769px) {
      .footer .tel dt,
      .footer .tel dd {
        font-size: 12px;
        font-size: 1.2rem; } }

.footer__link-area {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .footer__link-area {
      margin-bottom: 20px;
      justify-content: space-around; } }

.footer__navi {
  display: flex;
  flex-wrap: wrap;
  width: 60%; }
  @media screen and (max-width: 769px) {
    .footer__navi {
      width: 100%; } }
  .footer__navi li {
    box-sizing: border-box;
    min-width: 180px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .footer__navi li {
        padding-left: 0px;
        min-width: inherit;
        width: 100%; } }
    .footer__navi li a {
      color: #00acbd; }

.footer .contact {
  background: #00acbd;
  width: 100px;
  padding: 8px 0 0 8px;
  border: solid 2px #00acbd;
  box-shadow: 1px 2px 1px rgba(0, 172, 189, 0.5); }
  .footer .contact img {
    display: block;
    width: 100%; }

.footer .copyright {
  text-align: center;
  font-size: 13px;
  font-size: 1.3rem; }
  @media screen and (max-width: 769px) {
    .footer .copyright {
      font-size: 11px;
      font-size: 1.1rem; } }

.page-index .kv {
  background: url(../img/top/key_visual.png) no-repeat center bottom;
  background-size: cover; }

.page-index .content {
  counter-reset: number; }
  .page-index .content__ttl {
    margin-bottom: 50px;
    padding: 30px 0 15px;
    text-align: center;
    border-bottom: solid 1px #ccc;
    font-size: 25px;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-index .content__ttl {
        margin-bottom: 25px;
        padding: 15px 0 8px;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-index .wrap {
    width: 880px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-index .wrap {
    width: auto;
    margin: 0 5%; } }

.page-index .lead {
  margin-bottom: 100px;
  font-weight: bold; }
  @media screen and (max-width: 769px) {
    .page-index .lead {
      margin-bottom: 50px; } }
  .page-index .lead__ttl {
    margin-bottom: 10px;
    text-align: center;
    font-size: 22px;
    font-size: 2.2rem;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-index .lead__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
  .page-index .lead__text {
    word-break: break-all; }
    @media screen and (min-width: 770px) {
      .page-index .lead__text {
        font-size: 18px;
        font-size: 1.8rem;
        width: 64%;
        margin: 0 auto; } }
    @media screen and (max-width: 769px) {
      .page-index .lead__text {
        font-size: 14px;
        font-size: 1.4rem; } }

.page-index .tel-info {
  width: 740px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 70px;
  border-radius: 7px;
  line-height: 1.4;
  background: rgba(0, 172, 189, 0.1);
  font-size: 50px;
  font-size: 5rem; }
  @media screen and (max-width: 769px) {
    .page-index .tel-info {
      width: auto;
      padding: 20px 5%;
      font-size: 30px;
      font-size: 3rem; } }
  .page-index .tel-info__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #00acbd; }
    .page-index .tel-info__item:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    .page-index .tel-info__item .note {
      font-size: 17px;
      font-size: 1.7rem; }
      @media screen and (max-width: 769px) {
        .page-index .tel-info__item .note {
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-index .tel-info .item--tel::before {
    content: "";
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_tel.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-index .tel-info .item--tel::before {
        width: 28px;
        height: 28px;
        margin-right: 5%; } }
  .page-index .tel-info .item--tel a {
    display: block; }
  .page-index .tel-info .item--fax::before {
    content: "";
    display: block;
    width: 56px;
    height: 81px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_fax.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-index .tel-info .item--fax::before {
        width: 28px;
        height: 40px;
        margin-right: 5%; } }
  .page-index .tel-info .item--fax a {
    display: block; }

.page-index .block {
  margin-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-index .block {
      margin-bottom: 60px; } }
  .page-index .block__ttl {
    margin-bottom: 50px;
    font-size: 22px;
    font-size: 2.2rem;
    letter-spacing: 0.12em;
    text-align: center;
    font-weight: bold; }
    @media screen and (max-width: 769px) {
      .page-index .block__ttl {
        margin-bottom: 25px;
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-index .block__ttl--2 {
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl--2 {
          margin-bottom: 15px; } }
    .page-index .block__ttl.num::before {
      display: block;
      counter-increment: number;
      content: counter(number, decimal-leading-zero);
      font-size: 45px;
      font-size: 4.5rem;
      font-family: lato, sans-serif;
      line-height: 1;
      color: #00acbd; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl.num::before {
          font-size: 28px;
          font-size: 2.8rem; } }
    .page-index .block__ttl span {
      position: relative;
      display: inline-block;
      padding: 0 120px; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl span {
          padding: 0 30px; } }
      .page-index .block__ttl span::before, .page-index .block__ttl span::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 100px;
        height: 1px;
        background-color: #333; }
        @media screen and (max-width: 769px) {
          .page-index .block__ttl span::before, .page-index .block__ttl span::after {
            width: 20px; } }
      .page-index .block__ttl span::before {
        left: 0; }
      .page-index .block__ttl span:after {
        right: 0; }
  .page-index .block__list {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 769px) {
      .page-index .block__list {
        flex-direction: column; } }
    .page-index .block__list .list-wrap {
      width: 47%; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .list-wrap {
          width: auto;
          margin: 0 auto; } }
    .page-index .block__list .list {
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .list {
          margin-bottom: 15px; } }
    .page-index .block__list .case {
      display: flex;
      align-items: center;
      font-size: 27px;
      font-size: 2.7rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .case {
          font-size: 16px;
          font-size: 1.6rem; } }
      .page-index .block__list .case__img {
        width: 110px;
        margin-right: 16px; }
        @media screen and (max-width: 769px) {
          .page-index .block__list .case__img {
            width: 60px; } }
        .page-index .block__list .case__img img {
          display: block;
          width: 100%; }
    .page-index .block__list .ex {
      margin: 24px 0 60px;
      font-size: 24px;
      font-size: 2.4rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .ex {
          margin: 12px 0;
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-index .block__list .ex::before {
        content: "例：";
        color: #00acbd; }
      .page-index .block__list .ex li {
        display: flex;
        align-items: center;
        line-height: 2; }
        .page-index .block__list .ex li::before {
          content: "■";
          color: #00acbd;
          font-size: 12px;
          font-size: 1.2rem;
          margin-right: 1em; }
    .page-index .block__list .note {
      font-size: 18px;
      font-size: 1.8rem;
      color: #00acbd;
      text-align: center; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .note {
          margin-bottom: 10px;
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-index .block__img {
    margin-bottom: 100px; }
    @media screen and (max-width: 769px) {
      .page-index .block__img {
        margin-bottom: 50px; } }
    .page-index .block__img:last-child {
      margin-bottom: 0; }
    .page-index .block__img img {
      display: block;
      width: 100%; }
  .page-index .block__lead {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .page-index .block__lead {
        text-align: left;
        font-size: 14px;
        font-size: 1.4rem; } }
  .page-index .block__table {
    width: 100%; }
    .page-index .block__table tr:first-child th, .page-index .block__table tr:first-child td {
      border-bottom: none; }
    .page-index .block__table th, .page-index .block__table td {
      padding: 10px 20px;
      border-right: solid 1px #fff;
      border-bottom: solid 1px #fff;
      text-align: center;
      line-height: 1.4; }
      @media screen and (max-width: 769px) {
        .page-index .block__table th, .page-index .block__table td {
          padding: 10px; } }
    .page-index .block__table th {
      font-size: 19px;
      font-size: 1.9rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__table th {
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-index .block__table th.bg-light-main {
        color: #00acbd; }
    .page-index .block__table td {
      font-size: 18px;
      font-size: 1.8rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__table td {
          font-size: 12px;
          font-size: 1.2rem; } }
    .page-index .block__table .bg-main {
      background: #00acbd;
      color: #fff; }
    .page-index .block__table .bg-light-main {
      background: rgba(0, 172, 189, 0.1); }
    .page-index .block__table .bg-sub {
      background: #4c4948;
      color: #fff; }
    .page-index .block__table .bg-light-sub {
      background: rgba(76, 73, 72, 0.1); }
    .page-index .block__table .align-l {
      text-align: left; }

.appt_box {
  border: 3px solid #00acbd;
  margin: 90px auto 45px; }
  @media screen and (max-width: 769px) {
    .appt_box {
      margin: 50px 0 20px;
      border: 2px solid #00acbd; } }
  .appt_box__inner {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #00acbd; }
    @media screen and (max-width: 769px) {
      .appt_box__inner {
        display: block;
        border-bottom: 2px solid #00acbd; } }
  .appt_box__inner-2 {
    display: flex; }
    @media screen and (max-width: 769px) {
      .appt_box__inner-2 {
        display: block; } }
  .appt_box__part {
    padding: 40px;
    border-right: solid 3px #00acbd; }
    @media screen and (max-width: 769px) {
      .appt_box__part {
        padding: 20px;
        border-right: none;
        border-bottom: solid 2px #00acbd; } }
  .appt_box__part-2 {
    padding: 40px; }
    @media screen and (max-width: 769px) {
      .appt_box__part-2 {
        padding: 20px; } }
  .appt_box__title {
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em;
    border-left: solid 4px #00acbd;
    padding-left: 15px;
    margin-bottom: 30px;
    line-height: 1; }
    @media screen and (max-width: 769px) {
      .appt_box__title {
        font-size: 2rem;
        margin-bottom: 20px; } }
  .appt_box__txt {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.14em;
    margin-bottom: 20px; }
    @media screen and (max-width: 769px) {
      .appt_box__txt {
        font-size: 1.4rem; } }
  .appt_box__btn {
    font-size: 2rem;
    background-color: #00acbd;
    width: 350px;
    height: 50px;
    text-align: center;
    border-radius: 6px;
    line-height: 50px; }
    @media screen and (max-width: 769px) {
      .appt_box__btn {
        width: 100%;
        height: auto;
        line-height: 1.5;
        padding: 10px 0;
        font-size: 1.6rem; } }
    .appt_box__btn:hover {
      opacity: 0.7;
      text-decoration: none; }
    .appt_box__btn a {
      display: inline-block;
      width: 100%;
      color: #fff; }
  .appt_box__btn-2 {
    font-size: 2rem;
    background-color: #00acbd;
    width: 350px;
    height: 50px;
    text-align: center;
    border-radius: 6px;
    line-height: 50px;
    margin-right: 50px; }
    @media screen and (max-width: 769px) {
      .appt_box__btn-2 {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
        height: auto;
        line-height: 1.5;
        padding: 10px 0;
        font-size: 1.6rem; } }
    .appt_box__btn-2:hover {
      opacity: 0.7;
      text-decoration: none; }
    .appt_box__btn-2 a {
      display: inline-block;
      width: 100%;
      color: #fff; }
  .appt_box__tel {
    font-size: 1.4rem;
    line-height: 1.4;
    display: flex; }
    @media screen and (max-width: 769px) {
      .appt_box__tel {
        font-size: 1.2rem;
        display: flex;
        justify-content: center; } }
    .appt_box__tel:before {
      margin: 5px 10px 0 0;
      content: " ";
      display: inline-block;
      width: 50px;
      height: 50px;
      background: url(../img/top/icon_tel.png) no-repeat;
      background-size: contain;
      vertical-align: middle; }
    .appt_box__tel span {
      font-size: 2.8rem;
      font-weight: normal; }
      @media screen and (max-width: 769px) {
        .appt_box__tel span {
          font-size: 2.6rem; } }

.info-box {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .info-box {
      display: block; } }
  .info-box .info-inner {
    margin-left: 60px; }
    @media screen and (max-width: 769px) {
      .info-box .info-inner {
        margin-left: 0; } }
  .info-box .info__ttl {
    font-size: 2rem; }
    @media screen and (max-width: 769px) {
      .info-box .info__ttl {
        font-size: 1.8rem;
        margin-top: 20px; } }

.address__root {
  background-color: #00acbd;
  font-size: 1.4rem;
  border-radius: 20px;
  width: 200px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin-top: 20px; }
  .address__root:hover {
    opacity: 0.7;
    text-decoration: none; }
  .address__root a {
    display: inline-block;
    width: 100%;
    color: #fff; }

/* 臨時
	====================================================*/
#crn_news {
  width: 500px;
  border: solid red 1px;
  padding: 13px;
  margin: 60px auto 40px; }

#crn_news a {
  display: block; }

#crn_news a:hover {
  text-decoration: none;
  color: #666;
  opacity: 0.8; }

#crn_news p {
  font-size: 18px;
  text-align: center;
  line-height: 2; }

#crn_news span {
  font-size: 20px;
  background-color: red;
  padding: 5px 8px;
  color: #fff; }

#crn_news br.crn_sp {
  display: none; }

@media screen and (max-width: 768px) {
  #crn_news {
    width: 80%; }
  #crn_news span {
    font-size: 18px; }
  #crn_news br.crn_sp {
    display: block; } }

.page-outpatient .kv {
  padding-top: 0; }

.page-outpatient .main-kv {
  position: relative; }
  .page-outpatient .main-kv__text {
    position: absolute;
    top: 150px;
    left: 0;
    width: 70%;
    height: 240px;
    background: rgba(0, 172, 189, 0.7); }
    @media screen and (max-width: 769px) {
      .page-outpatient .main-kv__text {
        width: 80%;
        top: 80px;
        height: 320px; } }
  .page-outpatient .main-kv .inner {
    max-width: 1024px;
    margin: 0 auto;
    height: 0; }

.page-outpatient .keyvisual-inner-wrap {
  padding-top: 0;
  position: relative; }

.page-outpatient .keyvisual-slider {
  overflow: hidden;
  height: 70vh;
  max-height: 560px; }
  .page-outpatient .keyvisual-slider__main {
    transform-origin: center center; }
    .page-outpatient .keyvisual-slider__main .bg-images1 {
      height: 70vh;
      max-height: 560px;
      background: url(../img/top/kv_img_1.jpg) no-repeat center bottom;
      background-size: cover; }
      @media screen and (max-width: 769px) {
        .page-outpatient .keyvisual-slider__main .bg-images1 {
          background: url(../img/top/kv_img_sp_1.jpg) no-repeat center bottom; } }
    .page-outpatient .keyvisual-slider__main .bg-images2 {
      height: 70vh;
      max-height: 560px;
      background: url(../img/top/kv_img_2.png) no-repeat center bottom;
      background-size: cover; }
      @media screen and (max-width: 769px) {
        .page-outpatient .keyvisual-slider__main .bg-images2 {
          background: url(../img/top/kv_img_sp_2.png) no-repeat center bottom;
          width: auto; } }
    .page-outpatient .keyvisual-slider__main .bg-images3 {
      height: 70vh;
      max-height: 560px;
      background: url(../img/top/kv_img_3.png) no-repeat center bottom;
      background-size: cover; }
      @media screen and (max-width: 769px) {
        .page-outpatient .keyvisual-slider__main .bg-images3 {
          background: url(../img/top/kv_img_sp_3.png) no-repeat center bottom; } }

.page-outpatient .title-area-wrap {
  transform: translateY(-380px); }
  @media screen and (max-width: 769px) {
    .page-outpatient .title-area-wrap {
      transform: translateY(-370px); } }
  .page-outpatient .title-area-wrap .inner {
    width: 70%;
    margin: 0 auto 0 0; }
    @media screen and (max-width: 769px) {
      .page-outpatient .title-area-wrap .inner {
        margin: 0 auto 0 5%; } }

.page-outpatient .title-area {
  color: #fff; }
  .page-outpatient .title-area__title {
    font-size: 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: bold; }
    @media screen and (max-width: 769px) {
      .page-outpatient .title-area__title {
        font-size: 2rem;
        align-items: baseline;
        margin-bottom: 15px; } }
    .page-outpatient .title-area__title::before {
      margin-right: 10px;
      border-top: 1px solid #fff;
      content: "";
      flex-grow: 0.3; }
  .page-outpatient .title-area__text {
    font-size: 1.6rem;
    margin-bottom: 30px; }
    @media screen and (max-width: 769px) {
      .page-outpatient .title-area__text {
        font-size: 1.4rem;
        margin-bottom: 15px; } }
  .page-outpatient .title-area__tw {
    text-align: right;
    width: 90%;
    font-size: 1.6rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .title-area__tw {
        text-align: left;
        font-size: 1.4rem; } }
    .page-outpatient .title-area__tw img {
      width: 20px;
      vertical-align: middle;
      margin-right: 5px; }
      @media screen and (max-width: 769px) {
        .page-outpatient .title-area__tw img {
          width: 17px;
          margin-right: 4px; } }
    .page-outpatient .title-area__tw a {
      color: #fff; }

.page-outpatient .content {
  counter-reset: number; }
  .page-outpatient .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

.page-outpatient .outpatient {
  display: flex;
  justify-content: space-between; }
  @media screen and (min-width: 770px) {
    .page-outpatient .outpatient {
      width: 1024px;
      margin: 0 auto 30px; }
      .page-outpatient .outpatient:last-child {
        margin-bottom: 0; } }
  @media screen and (max-width: 769px) {
    .page-outpatient .outpatient {
      width: auto;
      margin: 0 5% 30px; } }
  @media screen and (max-width: 769px) {
    .page-outpatient .outpatient {
      flex-direction: column-reverse; } }

.page-outpatient .subjects {
  width: 495px;
  margin-bottom: 16px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .subjects {
      width: 100%;
      margin-bottom: 0;
      margin-top: 20px; } }
  .page-outpatient .subjects dt {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-size: 2rem;
    border-bottom: dotted 2px #c5c5c5;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .subjects dt {
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-outpatient .subjects dt.icon_course {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_course::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_course.png) no-repeat center;
        background-size: contain; }
    .page-outpatient .subjects dt.icon_physical-therapy {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_physical-therapy::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_physical-therapy.png) no-repeat center;
        background-size: contain; }
    .page-outpatient .subjects dt.icon_closed {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_closed::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_closed.png) no-repeat center;
        background-size: contain; }
  .page-outpatient .subjects dd {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.14em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .subjects dd {
        font-size: 14px;
        font-size: 1.4rem; } }

.page-outpatient .schedule {
  width: 498px;
  font-size: 16px;
  font-size: 1.6rem;
  border: solid 1px #c5c5c5; }
  @media screen and (max-width: 769px) {
    .page-outpatient .schedule {
      width: 100%;
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-outpatient .schedule .bg-main {
    background: #00acbd;
    color: #fff;
    border-right: solid 1px #fff;
    border-bottom: none; }
    .page-outpatient .schedule .bg-main:last-child {
      border: none; }
  .page-outpatient .schedule th, .page-outpatient .schedule td {
    padding: 10px;
    text-align: center;
    border-bottom: solid 1px #c5c5c5;
    border-right: solid 1px #c5c5c5; }
    .page-outpatient .schedule th.align-l, .page-outpatient .schedule td.align-l {
      text-align: left; }
    .page-outpatient .schedule th:last-child, .page-outpatient .schedule td:last-child {
      border-right: none; }
  .page-outpatient .schedule td {
    font-size: 18px;
    font-size: 1.8rem;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule td {
        font-size: 16px;
        font-size: 1.6rem; } }

.page-outpatient .note {
  margin-top: 8px;
  font-size: 13px;
  font-size: 1.3rem; }

@media screen and (min-width: 770px) {
  .page-outpatient .wrap {
    width: 800px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-outpatient .wrap {
    width: auto;
    margin: 0 5%; } }

.page-outpatient .block {
  margin-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .block {
      margin-bottom: 60px; } }
  .page-outpatient .block__ttl {
    margin-bottom: 30px;
    font-size: 30px;
    font-size: 3rem;
    letter-spacing: 0.12em;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .page-outpatient .block__ttl {
        margin-bottom: 15px;
        font-size: 20px;
        font-size: 2rem; } }
    .page-outpatient .block__ttl span {
      position: relative;
      display: inline-block;
      padding: 0 300px;
      width: 200px; }
      @media screen and (max-width: 769px) {
        .page-outpatient .block__ttl span {
          padding: 0 64px;
          width: 160px; } }
      .page-outpatient .block__ttl span::before, .page-outpatient .block__ttl span::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 300px;
        height: 1px;
        background-color: #00acbd; }
        @media screen and (max-width: 769px) {
          .page-outpatient .block__ttl span::before, .page-outpatient .block__ttl span::after {
            width: 64px; } }
      .page-outpatient .block__ttl span::before {
        left: 0; }
      .page-outpatient .block__ttl span:after {
        right: 0; }
  .page-outpatient .block__list {
    list-style: none;
    font-size: 1.6rem; }

.page-outpatient .news {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .news {
      align-items: flex-start; } }
  .page-outpatient .news__date {
    margin-right: 2em;
    font-size: 13px;
    font-size: 1.3rem; }
  .page-outpatient .news__text {
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .news__text {
        word-break: break-all;
        font-size: 14px;
        font-size: 1.4rem; } }

.page-outpatient .news-more {
  text-align: right;
  font-size: 14px;
  font-size: 1.4rem; }
  .page-outpatient .news-more a {
    position: relative;
    display: inline-block;
    padding: 0 12px 0 0;
    vertical-align: middle;
    text-decoration: none; }
    .page-outpatient .news-more a::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2px;
      margin: auto;
      vertical-align: middle;
      content: "";
      width: 6px;
      height: 6px;
      border-top: 2px solid #00acbd;
      border-right: 2px solid #00acbd;
      transform: rotate(45deg); }

.page-outpatient .access .map {
  width: 980px;
  margin: 0 auto 30px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .access .map {
      width: 100%; }
      .page-outpatient .access .map iframe {
        width: 100%; } }

.page-outpatient .access .address,
.page-outpatient .access .info {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.14em; }
  @media screen and (max-width: 769px) {
    .page-outpatient .access .address,
    .page-outpatient .access .info {
      font-size: 14px;
      font-size: 1.4rem; } }

.page-outpatient .access .address {
  margin-bottom: calc(1em * 1.5); }

.page-outpatient .access .info__ttl {
  color: #00acbd;
  margin-bottom: calc(1em * 1.5); }

.page-outpatient .access .info__text {
  margin-bottom: calc(1em * 1.5); }
  .page-outpatient .access .info__text a {
    color: #00acbd; }
  .page-outpatient .access .info__text::before {
    content: "□"; }

.page-about .kv {
  background: url(../img/about/key_visual.png) no-repeat center bottom;
  background-size: cover; }

.page-about .content {
  counter-reset: number; }
  .page-about .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-about .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-about .wrap {
    width: 900px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-about .wrap {
    width: auto;
    margin: 0 5%; } }

.page-about .table {
  width: 100%;
  border: solid 1px #00acbd;
  margin-bottom: 65px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (max-width: 769px) {
    .page-about .table {
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-about .table th,
  .page-about .table td {
    padding: 15px 20px; }
    @media screen and (max-width: 769px) {
      .page-about .table th,
      .page-about .table td {
        padding: 10px; } }
  .page-about .table th {
    width: 28%;
    background: #00acbd;
    color: #fff;
    border-bottom: solid 1px #fff; }
  .page-about .table td {
    border-bottom: solid 1px #00acbd; }
    .page-about .table td a {
      color: #00acbd; }

.page-staff .kv {
  background: url(../img/staff/key_visual.png) no-repeat center bottom;
  background-size: cover; }

.page-staff .content {
  counter-reset: number; }
  .page-staff .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-staff .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-staff .wrap {
    width: 900px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-staff .wrap {
    width: auto;
    margin: 0 5%; } }

.page-staff .staff {
  display: flex;
  margin-bottom: 50px; }
  @media screen and (max-width: 769px) {
    .page-staff .staff {
      flex-direction: column; } }
  .page-staff .staff:last-child {
    margin-bottom: 60px; }
  .page-staff .staff__img {
    width: 150px;
    height: 200px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    @media screen and (max-width: 769px) {
      .page-staff .staff__img {
        margin: 0 auto 15px; } }
    .page-staff .staff__img img {
      width: 100%; }
  .page-staff .staff__text-area {
    width: calc(100% - 180px);
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5; }
    @media screen and (max-width: 769px) {
      .page-staff .staff__text-area {
        width: 100%;
        font-size: 14px;
        font-size: 1.4rem; } }
  .page-staff .staff__item {
    margin-bottom: calc(1em * 1.5); }
    .page-staff .staff__item:last-child {
      margin-bottom: 0; }
  .page-staff .staff__head {
    color: #00acbd; }
  .page-staff .staff__name {
    padding-bottom: 10px;
    border-bottom: solid 1px #00acbd; }
    .page-staff .staff__name span {
      font-size: 20px;
      font-size: 2rem;
      margin-right: 0.5em; }
      @media screen and (max-width: 769px) {
        .page-staff .staff__name span {
          font-size: 18px;
          font-size: 1.8rem; } }

.page-visiting-care .kv {
  background: url(../img/visiting_care/key_visual.png) no-repeat center bottom;
  background-size: cover; }

.page-visiting-care .content {
  counter-reset: number; }
  .page-visiting-care .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-visiting-care .wrap {
    width: 800px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-visiting-care .wrap {
    width: auto;
    margin: 0 5%; } }

.page-visiting-care .block {
  margin-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .block {
      margin-bottom: 60px; } }
  .page-visiting-care .block__ttl {
    margin-bottom: 30px;
    font-size: 30px;
    font-size: 3rem;
    letter-spacing: 0.12em;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .block__ttl {
        margin-bottom: 15px;
        font-size: 20px;
        font-size: 2rem; } }
    .page-visiting-care .block__ttl span {
      position: relative;
      display: inline-block;
      padding: 0 260px;
      width: 280px; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .block__ttl span {
          padding: 0 54px;
          width: 180px; } }
      .page-visiting-care .block__ttl span::before, .page-visiting-care .block__ttl span::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 260px;
        height: 1px;
        background-color: #00acbd; }
        @media screen and (max-width: 769px) {
          .page-visiting-care .block__ttl span::before, .page-visiting-care .block__ttl span::after {
            width: 54px; } }
      .page-visiting-care .block__ttl span::before {
        left: 0; }
      .page-visiting-care .block__ttl span:after {
        right: 0; }

.page-visiting-care .tel-info {
  width: 740px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 70px;
  border-radius: 7px;
  line-height: 1.4;
  background: rgba(0, 172, 189, 0.1);
  font-size: 50px;
  font-size: 5rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .tel-info {
      width: auto;
      padding: 20px 5%;
      font-size: 30px;
      font-size: 3rem; } }
  .page-visiting-care .tel-info__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #00acbd; }
    .page-visiting-care .tel-info__item:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    .page-visiting-care .tel-info__item .note {
      font-size: 17px;
      font-size: 1.7rem; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .tel-info__item .note {
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-visiting-care .tel-info .item--tel::before {
    content: "";
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_tel.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .tel-info .item--tel::before {
        width: 28px;
        height: 28px;
        margin-right: 5%; } }
  .page-visiting-care .tel-info .item--tel a {
    display: block; }
    .page-visiting-care .tel-info .item--tel a span {
      display: block;
      font-size: 12px; }
  .page-visiting-care .tel-info .item--fax::before {
    content: "";
    display: block;
    width: 56px;
    height: 81px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_fax.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .tel-info .item--fax::before {
        width: 28px;
        height: 40px;
        margin-right: 5%; } }
  .page-visiting-care .tel-info .item--fax a {
    display: block; }

.page-visiting-care .area {
  display: flex; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .area {
      flex-direction: column; } }
  .page-visiting-care .area__img {
    width: 400px;
    margin-right: 30px; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__img {
        width: 100%;
        margin: 0 0 15px; } }
    .page-visiting-care .area__img img {
      display: block;
      width: 100%; }
  .page-visiting-care .area__text-area {
    width: calc(100% - 430px); }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__text-area {
        width: 100%; } }
  .page-visiting-care .area__item {
    width: 100%;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.75;
    margin-bottom: calc(1em * 1.75); }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__item {
        font-size: 16px;
        font-size: 1.6rem; } }
  .page-visiting-care .area__head {
    color: #00acbd; }

.page-visiting-care .point__item {
  display: flex;
  margin-bottom: 60px; }
  .page-visiting-care .point__item:last-child {
    margin-bottom: 0; }

.page-visiting-care .point__img {
  width: 160px;
  margin-right: 40px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__img {
      width: 80px;
      margin-right: 20px; } }
  .page-visiting-care .point__img img {
    display: block;
    width: 100%; }

.page-visiting-care .point__text-area {
  width: calc(100% - 200px);
  margin-top: 10px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__text-area {
      width: calc(100% - 100px);
      margin-top: 0; } }

.page-visiting-care .point__head {
  margin-bottom: 10px;
  font-size: 26px;
  font-size: 2.6rem;
  color: #00acbd; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__head {
      margin-bottom: 5px;
      font-size: 18px;
      font-size: 1.8rem; } }

.page-visiting-care .point__text {
  font-size: 18px;
  font-size: 1.8rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__text {
      font-size: 14px;
      font-size: 1.4rem; } }

.page-visiting-care .lead__text {
  font-size: 18px;
  font-size: 1.8rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .lead__text {
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-visiting-care .lead__text a {
    color: #00acbd;
    text-decoration: underline; }

.page-visiting-care .list-area {
  width: 700px;
  margin: 0 auto 70px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .list-area {
      width: 100%; } }
  .page-visiting-care .list-area__notes {
    font-size: 12px;
    margin-top: 5px; }
  .page-visiting-care .list-area:last-child {
    margin-bottom: 0; }
  .page-visiting-care .list-area__ttl {
    width: 185px;
    margin: 0 auto 30px;
    background: #00acbd;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    border-radius: 6px;
    line-height: 2.2; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .list-area__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
  .page-visiting-care .list-area .list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #00acbd; }
    .page-visiting-care .list-area .list li {
      box-sizing: border-box;
      width: 50%;
      padding: 10px;
      font-size: 18px;
      font-size: 1.8rem;
      border-bottom: solid 1px #00acbd; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .list-area .list li {
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-visiting-care .list-area .list li:nth-child(odd) {
        border-right: solid 1px #00acbd; }
      .page-visiting-care .list-area .list li:nth-child(4n-1), .page-visiting-care .list-area .list li:nth-child(4n) {
        background: rgba(0, 172, 189, 0.1); }
      .page-visiting-care .list-area .list li:last-child, .page-visiting-care .list-area .list li:nth-last-child(2) {
        border-bottom: none; }
  .page-visiting-care .list-area .note {
    font-size: 14px;
    font-size: 1.4rem; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .list-area .note {
        font-size: 12px;
        font-size: 1.2rem; } }

.page-visiting-care .flow {
  width: 745px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .flow {
      width: 100%;
      flex-direction: column; } }
  .page-visiting-care .flow__item {
    position: relative;
    width: 180px; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__item {
        padding-bottom: 40px;
        margin-bottom: 20px;
        width: 100%; } }
    .page-visiting-care .flow__item:last-child::after {
      display: none; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__item:last-child {
        padding-bottom: 0;
        margin-bottom: 0; } }
    .page-visiting-care .flow__item::after {
      position: absolute;
      right: -78px;
      top: 40px;
      content: "";
      display: block;
      width: 50px;
      height: 44px;
      background: url(../img/visiting_care/icon_arrow.png) no-repeat;
      background-size: contain; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .flow__item::after {
          right: 0;
          left: 0;
          top: inherit;
          bottom: 0;
          margin: auto;
          width: 25px;
          height: 22px;
          transform: rotate(90deg); } }
  .page-visiting-care .flow__ttl {
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-visiting-care .flow__ttl .note {
      font-size: 16px;
      font-size: 1.6rem; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .flow__ttl .note {
          font-size: 14px;
          font-size: 1.4rem; } }
  .page-visiting-care .flow__img {
    display: block;
    width: 98px;
    margin: 0 auto 10px; }
  .page-visiting-care .flow__text {
    font-size: 15px;
    font-size: 1.5rem; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__text {
        font-size: 14px;
        font-size: 1.4rem; } }

.page-recruit .kv {
  background: url(../img/recruit/key_visual.png) no-repeat center bottom;
  background-size: cover; }

.page-recruit .content {
  counter-reset: number; }
  .page-recruit .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-recruit .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-recruit .wrap {
    width: 1024px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-recruit .wrap {
    width: auto;
    margin: 0 5%; } }

.page-recruit .recruit {
  margin-bottom: 120px; }
  .page-recruit .recruit__ttl {
    margin-bottom: 30px;
    font-size: 23px;
    font-size: 2.3rem;
    text-align: center; }
    .page-recruit .recruit__ttl__img {
      width: 60px;
      margin: 0 auto 10px; }
      .page-recruit .recruit__ttl__img img {
        width: 100%;
        display: block; }

.page-recruit .recruit_position > p {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem; }

.page-recruit .recruit_position > ul > li {
  margin-bottom: 30px; }

.page-recruit .recruit_position > ul > li > a {
  display: flex;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 10px 20px 10px 10px; }

.page-recruit .recruit_position > ul > li > a:hover {
  opacity: 0.7;
  text-decoration: none; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_img {
  width: 391px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt {
  width: 615px;
  padding: 10px 0; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt h3 {
  margin-bottom: 15px;
  color: #00acbd;
  font-weight: 500;
  font-size: 20px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt p {
  color: #333;
  line-height: 1.5;
  font-size: 16px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_more {
  position: relative;
  margin-bottom: 15px;
  padding-right: 10px;
  color: #00acbd;
  text-align: right;
  font-size: 14px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_more::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    margin: auto;
    vertical-align: middle;
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #00acbd;
    border-right: 2px solid #00acbd;
    transform: rotate(45deg); }
    @media screen and (max-width: 769px) {
      .page-recruit .recruit_position > ul > li > a .recruit_position_list_more::after {
        width: 5px;
        height: 5px; } }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_tag li {
  display: inline-block;
  margin: 0 5px 5px 0;
  border-radius: 1em;
  background-color: #00acbd;
  padding: 0.5em 1em;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-size: 16px; }

@media screen and (max-width: 769px) {
  .page-recruit .recruit_position {
    margin: 0 auto;
    width: 100%; }
  .page-recruit .recruit_position > p {
    margin-bottom: 10px; }
  .page-recruit .recruit_position > ul > li {
    margin-bottom: 15px; }
  .page-recruit .recruit_position > ul > li > a {
    display: block;
    padding: 10px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_img {
    display: none; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt {
    width: 100%;
    padding: 0; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt h3 {
    margin-bottom: 10px;
    font-size: 14px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt p {
    font-size: 12px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_more {
    margin-bottom: 10px;
    font-size: 10px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_tag li {
    font-size: 11px; } }

.page-index .content__title {
  padding: 45px 0 30px;
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.12em;
  border-bottom: none; }
  @media screen and (max-width: 769px) {
    .page-index .content__title {
      font-size: 2rem;
      padding: 15px 0; } }

.page-index .content .lead__txt {
  font-size: 18px;
  font-size: 1.8rem;
  width: 100%;
  margin: 0 auto; }
  @media screen and (max-width: 769px) {
    .page-index .content .lead__txt {
      font-size: 1.4rem; } }

.page-index .content .form-area-wrap {
  width: 1024px;
  margin: 0 auto 120px; }
  @media screen and (max-width: 769px) {
    .page-index .content .form-area-wrap {
      width: 100%; } }
  .page-index .content .form-area-wrap .inner {
    padding: 60px;
    border: 2px solid #00acbd; }
    @media screen and (max-width: 769px) {
      .page-index .content .form-area-wrap .inner {
        padding: 20px;
        margin: 0 5%; } }
    .page-index .content .form-area-wrap .inner .form-area {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 769px) {
        .page-index .content .form-area-wrap .inner .form-area {
          display: block; } }
      .page-index .content .form-area-wrap .inner .form-area__title {
        font-size: 2rem;
        line-height: 1;
        width: 40%;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start; }
        @media screen and (max-width: 769px) {
          .page-index .content .form-area-wrap .inner .form-area__title {
            margin-bottom: 15px;
            width: 100%;
            display: block;
            font-size: 1.6rem; } }
        @media screen and (max-width: 769px) {
          .page-index .content .form-area-wrap .inner .form-area__title p {
            display: inline-block; } }
        .page-index .content .form-area-wrap .inner .form-area__title .required {
          color: #fff;
          background-color: #00acbd;
          font-size: 1.4rem;
          display: inline-block;
          margin: 0 30px 0 0;
          padding: 5px 10px; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__title .required {
              margin: 0 0 0 20px;
              font-size: 1.2rem; } }
        .page-index .content .form-area-wrap .inner .form-area__title .title-full {
          font-size: 1.4rem;
          width: 100%; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__title .title-full {
              font-size: 1.2rem; } }
      .page-index .content .form-area-wrap .inner .form-area__txt-1 {
        color: #333;
        font-size: 20px;
        margin-right: 60px; }
        @media screen and (max-width: 769px) {
          .page-index .content .form-area-wrap .inner .form-area__txt-1 {
            margin-right: 10px;
            display: inline-block;
            margin-bottom: 20px;
            font-size: 1.6rem; } }
        .page-index .content .form-area-wrap .inner .form-area__txt-1:last-child {
          margin-right: 0px; }
        .page-index .content .form-area-wrap .inner .form-area__txt-1 input {
          margin-right: 20px; }
        .page-index .content .form-area-wrap .inner .form-area__txt-1 span {
          color: #fff;
          background-color: #00acbd;
          font-size: 1.4rem;
          display: inline-block;
          margin-right: 30px;
          padding: 0 10px; }
      .page-index .content .form-area-wrap .inner .form-area__txt-2 {
        margin-bottom: 40px;
        width: 60%; }
        @media screen and (max-width: 769px) {
          .page-index .content .form-area-wrap .inner .form-area__txt-2 {
            margin-bottom: 20px;
            width: 100%; } }
        .page-index .content .form-area-wrap .inner .form-area__txt-2 input {
          width: 400px;
          padding: 10px;
          font-size: 1.6rem; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__txt-2 input {
              width: calc(100%);
              font-size: 16px;
              transform: scale(1);
              border: 1px solid #767676; } }
        .page-index .content .form-area-wrap .inner .form-area__txt-2 textarea {
          width: 400px;
          padding: 10px;
          color: #333;
          font-size: 1.6rem;
          font-family: 'Noto Sans JP', sans-serif; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__txt-2 textarea {
              width: calc(100%);
              font-size: 16px;
              transform: scale(1); } }
      .page-index .content .form-area-wrap .inner .form-area__txt-3 {
        margin-bottom: 40px;
        width: 60%; }
        @media screen and (max-width: 769px) {
          .page-index .content .form-area-wrap .inner .form-area__txt-3 {
            margin-bottom: 20px;
            width: 100%; } }
        .page-index .content .form-area-wrap .inner .form-area__txt-3 input {
          width: 400px;
          padding: 10px;
          font-size: 1.6rem;
          color: #333; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__txt-3 input {
              width: calc(100% - 20px);
              font-size: 16px;
              transform: scale(1);
              border: 1px solid #767676; } }
          .page-index .content .form-area-wrap .inner .form-area__txt-3 input:after {
            margin: 0 10px 0 0;
            content: " ";
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url(../img/contact/icon-schedule.png) no-repeat;
            background-size: contain;
            vertical-align: middle; }
        .page-index .content .form-area-wrap .inner .form-area__txt-3 input[type="date"] {
          position: relative;
          color: #767676; }
        .page-index .content .form-area-wrap .inner .form-area__txt-3 input[type=date]::-webkit-calendar-picker-indicator {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0; }
      .page-index .content .form-area-wrap .inner .form-area__btn {
        text-align: center; }
        .page-index .content .form-area-wrap .inner .form-area__btn input {
          color: #FFF;
          background: #00acbd;
          border: none;
          font-size: 2.4rem;
          width: 250px;
          padding: 10px 0;
          border-radius: 6px;
          letter-spacing: 0.5em; }
          @media screen and (max-width: 769px) {
            .page-index .content .form-area-wrap .inner .form-area__btn input {
              font-size: 1.6rem;
              width: 50%; } }

.page-index .error-message {
  display: block;
  width: 100%;
  font-size: 14px;
  color: red;
  margin-top: 10px; }

.page-index .thanks {
  width: 80%;
  max-width: 1024px;
  margin: 50px auto;
  text-align: center; }
  @media screen and (max-width: 769px) {
    .page-index .thanks {
      width: 90%;
      margin: 30px auto 50px; } }
  .page-index .thanks__ttl {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 30px; }
    @media screen and (max-width: 769px) {
      .page-index .thanks__ttl {
        font-size: 2.0rem; } }
  .page-index .thanks__text {
    font-size: 1.6rem;
    margin-bottom: 20px; }
    @media screen and (max-width: 769px) {
      .page-index .thanks__text {
        font-size: 1.4rem; } }

.dummy {
  color: red !important; }
