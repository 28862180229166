@include sp-screen {}

.page-index {
  .content {
    &__title{
      padding: 45px 0 30px;
      text-align: center;
      font-size: 30px;
      font-size: 3rem;
      font-weight: normal;
      letter-spacing: 0.12em;
      border-bottom: none;
      @include sp-screen {
        font-size: 2rem;
        padding: 15px 0;
      }
    }
    .lead{
      &__txt {
        font-size: 18px;
        font-size: 1.8rem;
        width: 100%;
        margin: 0 auto;
        @include sp-screen {
          font-size: 1.4rem;
        }
    }
    }
    .form-area-wrap{
      width: 1024px;
      margin: 0 auto 120px;
      @include sp-screen {
        width: 100%;

      }
      .inner{
        padding: 60px;
        border: 2px solid #00acbd;
        @include sp-screen {
          padding: 20px;
          margin: 0 5%;
        }
        .form-area{
          display:flex;
          flex-wrap: wrap;
          @include sp-screen {
            display: block;
          }
          &__title{
            font-size: 2rem;
            line-height: 1;
            width: 40%;
            margin-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            @include sp-screen {
              margin-bottom: 15px;
              width: 100%;
              display: block;
              font-size: 1.6rem;
            }
            p{
              @include sp-screen {
                display: inline-block;
              }
            }
            .required{
              color: #fff;
              background-color: #00acbd;
              font-size: 1.4rem;
              display: inline-block;
              margin: 0 30px 0 0;
              padding: 5px 10px;
              @include sp-screen {
                margin: 0 0 0 20px;
                font-size: 1.2rem;
              }
            }
            .title-full{
              font-size: 1.4rem;
              width: 100%;
              @include sp-screen {
                font-size: 1.2rem;
              }
            }
          }
          &__txt-1{
            color: #333;
            font-size: 20px;
            margin-right: 60px;
            @include sp-screen {
              margin-right: 10px;
              display: inline-block;
              margin-bottom: 20px;
              font-size: 1.6rem;
            }
            &:last-child{
              margin-right: 0px;
            }
            input{
              margin-right: 20px;
            }
            span{
              color: #fff;
              background-color: #00acbd;
              font-size: 1.4rem;
              display: inline-block;
              margin-right: 30px;
              padding: 0 10px;
            }
          }
          &__txt-2{
            margin-bottom: 40px;
            width: 60%;
            @include sp-screen {
              margin-bottom: 20px;
              width: 100%;
            }
            input{
              width: 400px;
              padding: 10px;
              font-size: 1.6rem;
              @include sp-screen {
                width: calc(100%);
                font-size: 16px;
                transform: scale(1);
                border: 1px solid rgb(118, 118, 118);
              }
            }
            textarea{
              width: 400px;
              padding: 10px;
              color: #333;
              font-size: 1.6rem;
              // border: 1px solid #aaa;
              font-family: 'Noto Sans JP', sans-serif;
              @include sp-screen {
                width: calc(100%);
                font-size: 16px;
                transform: scale(1);
              }
            }
          }
          &__txt-3{
            margin-bottom: 40px;
            width: 60%;
            @include sp-screen {
              margin-bottom: 20px;
              width: 100%;
            }
            input{
              width: 400px;
              padding: 10px;
              font-size: 1.6rem;
              color: #333;
              @include sp-screen {
                width: calc(100% - 20px);
                font-size: 16px;
                transform: scale(1);
                border: 1px solid rgb(118, 118, 118);
              }
              &:after{
                margin:0 10px 0 0;
                content:" ";
                display:inline-block;
                width:30px;
                height:30px;
                background:url( ../img/contact/icon-schedule.png) no-repeat;    
                background-size:contain;
                vertical-align:middle;
              }
            }
            input[type="date"]{
              position: relative;
              color: rgb(118, 118, 118);
            }
          input[type=date]::-webkit-calendar-picker-indicator {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
          &__btn{
            text-align: center;
            input{
              color: #FFF;
              background: #00acbd;
              border: none;
              font-size: 2.4rem;
              width: 250px;
              padding: 10px 0;
              border-radius: 6px;
              letter-spacing: 0.5em;
              @include sp-screen {
                font-size: 1.6rem;
                width: 50%;

              }
            }
          }
        }
      }
    }
  }
  .error-message {
    display: block;
    width: 100%;
    font-size: 14px;
    color: red;
    margin-top: 10px;
  }
  .thanks {
    width: 80%;
    max-width: 1024px;
    margin: 50px auto;
    text-align: center;
    @include sp-screen {
      width: 90%;
      margin: 30px auto 50px;
    }
    &__ttl {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 30px;
      @include sp-screen {
        font-size: 2.0rem;
      }
    }
    &__text {
      font-size: 1.6rem;
      margin-bottom: 20px;
      @include sp-screen {
        font-size: 1.4rem;
      }
    }
  }
}