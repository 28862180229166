@include sp-screen {}

.page-staff {
  .kv {
    background: url(../img/staff/key_visual.png) no-repeat center bottom;
    background-size: cover;
  }
  .content {
    counter-reset: number;
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(30);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(20);
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 900px;
      margin: 0 auto;
    }
    @include sp-screen {
      width: auto;
      margin: 0 5%;
    }
  }
  .staff {
    display: flex;
    margin-bottom: 50px;
    @include sp-screen {
      flex-direction: column;
    }
    &:last-child {
      margin-bottom: 60px;
    }
    &__img {
      width: 150px;
      height:200px;
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      @include sp-screen {
        margin: 0 auto 15px;
      }
      img {
        width: 100%;
      }
    }
    &__text-area {
      width: calc(100% - 180px);
      @include rem-font-size(16);
      line-height: 1.5;
      @include sp-screen {
        width: 100%;
        @include rem-font-size(14);
      }
    }
    &__item {
      margin-bottom: calc(1em * 1.5);
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__head {
      color: $mainColor;
    }
    &__name {
      padding-bottom: 10px;
      border-bottom: solid 1px $mainColor;
      span {
        @include rem-font-size(20);
        margin-right: 0.5em;
        @include sp-screen {
          @include rem-font-size(18);
        }
      }
    }
  }
}
